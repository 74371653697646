@font-face {
    font-family: 'ZH';
    src: url("../assets/font/DIN-BlackItalicAlt.otf");
}
@font-face {
    font-family: 'CH';
    src: url("../assets/font/WenYue-XinQingNianTi-NC-W8.otf");
}
.playhome.active{
  top: 0;
}
.playhome{
  // position: fixed;
  // left: 0;
  // top: 100%;
  width: 100%;
  height: 100%;
  z-index: 4;
  transition: all .5s cubic-bezier(1, 0.08, 0.49, 0.86);
  .head{
    position: fixed;
    z-index: 10;
    display: flex;
    justify-content:flex-start;
    align-items:center;
    top: 0;
    left: 0;
    width: 100%;
    height: 1.6rem;
    background-color: #FFFFFF;
    transform: translateY(-100%);
    transition: transform .3s .3s;
    &.active{
      transform: translateY(0);
    }
    .logo{
      margin-left: 0.266666rem;
      width: 1.546666rem;
      height: 1.6rem;
    }
    .name{
      margin-left: 0.293333rem;
      .atyz{
        font-size: 0.426666rem;
        color: #151515;
        line-height: 0.586666rem;
        font-weight: bold;
      }
      p{
        line-height: 0.533333rem;
        font-size: 0.373333rem;
        color: #ACB0BE;
      }
    }
    .download{
      margin-left: 2rem;
      width:2.56rem;
      height:0.8rem;
      background:linear-gradient(161deg,rgba(255,59,100,1) 0%,rgba(255,26,26,1) 100%);
      border-radius:0.106666rem;
      line-height: 0.8rem;
      color: #FFFFFF;
      text-align: center;
      font-size: 0.4rem;
      span{
        font-family: 'ZH';
      }
    }
  }
  .playhome-center{
    width: 100%;
    background:rgba(21,21,21,1);
    .switch{
      position: relative;
      margin-top: 2.453333rem;
    }
    .userimg{
      display: block;
      margin: 0 auto;
      width: 7.76rem;
      height: 7.76rem;
      object-fit: cover;
    }
    .Mask{
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      width: 7.76rem;
      height: 7.76rem;
      background: rgba(0,0,0,0.05);
    }
    .playhome-play-btn{
      position: absolute;
      top: 3.093333rem;
      left: 50%;
      transform: translateX(-50%);
      height: 1.626666rem;
      .last-btn{
        float: left;
        margin-top: 0.533333rem;
        width: 0.453333rem;
        height: 0.533333rem;
        background: url("../../static/img/last.png") no-repeat;
        background-size: cover;
      }
      .paly-btn{
        float: left;
        margin-left: 1.2rem;
        width: 1.6rem;
        height: 1.6rem;
        background: url("../../static/img/play.png") no-repeat;
        background-size: cover;
      }
      .paly-btn.active{
        background: url("../../static/img/stop.png") no-repeat;
        background-size: cover;
      }
      .next-btn{
        float: left;
        margin-left: 1.2rem;
        margin-top: 0.533333rem;
        width: 0.453333rem;
        height: 0.533333rem;
        background: url("../../static/img/next.png") no-repeat;
        background-size: cover;
      }
    }
    .play-text{
      position: relative;
      margin-top: 0.853333rem;
      .play-name{
        margin-left: 0.693333rem;
        width: 6.773333rem;
        font-size: 0.533333rem;
        line-height: 0.746666rem;
        color: #FFFFFF;
        font-weight:600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .play-introduce{
        width: 6.773333rem;
        margin-top: 0.133333rem;
        margin-left: 0.693333rem;
        font-size: 0.4rem;
        line-height: 0.56rem;
        color: #BBBBBB;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .like{
        position: absolute;
        right: 0.613333rem;
        top: 0.56rem;
        width: 0.48rem;
        height: 0.453333rem;
        background: url(../../static/img/like.png) no-repeat;
        background-size: contain;
      }
    }
    .playhome-play{
      position: relative;
      margin: 0 auto;
      margin-top: 1.066666rem;
      width: 8.72rem;
      .aplayer-bar{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0.053333rem;
        background-color: #3A3A3A;
        .aplayer-loaded{
          position: absolute;
          left: 0;
          top: 0;
          background: #aaa;
          height: 100%;
          transition: all .5s ease;
        }
        .aplayer-played{
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          background-color: #FFFFFF;
        }
        .aplayer-played-icon{
          position: absolute;
          left: 0;
          top: 0;
          width: 0.346666rem;
          height: 0.346666rem;
          border-radius: 50%;
          transform: translate(-50%,-50%);
          background-color: #FFFFFF;
        }
      }
      .playhome-time{
        padding-top: 0.346666rem;
        width: 100%;
        height: 0.453333rem;
        .currentTime{
          float: left;
          font-size: 0.32rem;
          color: #B3B3B3;
        }
        .maxTime{
          float: right;
          font-size: 0.32rem;
          color: #B3B3B3;
        }
      }
    }
  }
  .more{
    margin-top: 0.506666rem;
    text-align: center;
    line-height: 0.533333rem;
    font-size: 0.373333rem;
    color: #ACB0BE;
    i{
      font-size: 0.213333rem;
    }
  }
  .program{
    .mtitle{
      margin-top: 1.706666rem;
      img{
        float: left;
        margin-left: 0.533333rem;
        margin-right: 0.266666rem;
        width: 0.746666rem;
        height: 0.746666rem;
      }
      h3{
        font-size:0.373333rem;
        font-family:DIN-RegularItalic,DIN;
        font-weight:normal;
        color:rgba(172,176,190,1);
        line-height:0.453333rem;
      }
    }
    ul{
      margin-top: 0.8rem;
      padding: 0 0.533333rem;
      li{
        width: 100%;
        height: 1.12rem;
        margin-bottom: 0.48rem;
        .music-li-img{
          float: left;
          width: 1.12rem;
          height: 1.12rem;
          img{
            width: 100%;
          }
        }
        .music-li-center{
          float: left;
          margin-left: 0.32rem;
          width: 5.333333rem;
          .music-name{
            font-size: 0.426666rem;
            line-height: 0.586666rem;
            font-weight:500;
            color: #FFFFFF;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          p{
            font-size: 0.373333rem;
            line-height: 0.533333rem;
            color: #9F9F9F;
          }
        }
        .play{
          float: right;
          margin-top: 0.266666rem;
          width: 0.613333rem;
          height: 0.613333rem;
          background: url(../../static/img/player.png) no-repeat;
          background-size: contain;
          &.vip{
            margin-top: 0.346666rem;
            width: 1.84rem;
            height: 0.453333rem;
            background: url(../../static/img/vipplay.png) no-repeat;
            background-size: contain;
          }
        }
      }
    }
  }
  .radio{
    .mtitle{
      margin-top: 1.706666rem;
      img{
        float: left;
        margin-left: 0.533333rem;
        margin-right: 0.266666rem;
        width: 0.746666rem;
        height: 0.746666rem;
      }
      h3{
        font-size:0.373333rem;
        font-family:DIN-RegularItalic,DIN;
        font-weight:normal;
        color:rgba(172,176,190,1);
        line-height:0.453333rem;
      }
    }
    ul{
      margin: 0 auto;
      padding-top: 0.8rem;
      padding-bottom: 0.533333rem;
      width: 8.933333rem;
      overflow: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width:0;
      }
      li{
        width: 100%;
        height: 4rem;
        margin-bottom: 0.533333rem;
        .radio-li-baner{
          float: left;
          width: 4rem;
          height: 4rem;
          box-shadow:0px 16px 56px 0px rgba(0,0,0,0.6);
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .radio-li-center{
          position: relative;
          float: left;
          width: 4.32rem;
          height: 4rem;
          margin-left: 0.426666rem;
        }
        .radio-li-title{
            font-size:0.426666rem;
            color: #FFFFFF;
            line-height: 0.586666rem;
            font-weight:600;
        }
        .radio-li-text{
          margin-top: 0.266666rem;
          font-size: 0.373333rem;
          line-height: 0.533333rem;
          color: #999999;
        }
        .radio-li-num{
          position: absolute;
          left: 0;
          bottom: 0.16rem;
          font-size: 0.426666rem;
          line-height: 0.586666rem;
          color: #B4B4B4;
        }
      }
    }
  }
  .bottom{
    padding-bottom: 1.333333rem;
    position: relative;
    width: 100%;
    height: 4.906666rem;
    background-color: #FFFFFF;
    img{
      position: absolute;
      top: 0.933333rem;
      left: 2.56rem;
      width: 1.546666rem;
      height: 1.6rem;
    }
    .name{
      position: absolute;
      left: 4.16rem;
      top: 1.2rem;
      .atyz{
        font-size: 0.426666rem;
        color: #151515;
        line-height: 0.586666rem;
        font-weight: bold;
      }
      p{
        line-height: 0.533333rem;
        font-size: 0.373333rem;
        color: #ACB0BE;
      }
    }
    .download{
      position: absolute;
      top: 3.466666rem;
      left: 50%;
      transform: translateX(-50%);
      width: 4.533333rem;
      height:0.8rem;
      background:rgba(37,39,44,1);
      border-radius:0.106666rem;
      line-height: 0.8rem;
      color: #FFFFFF;
      text-align: center;
      font-size: 0.4rem;
      box-shadow:0px 0.133333rem 0.4rem 0px rgba(37,39,44,.5);
      span{
        font-family: 'ZH';
      }
    }
  }
}