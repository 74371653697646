#aplayer {
  position: fixed;
  bottom: -2rem;
  left: 0;
  width: 100%;
  height: 1.333333rem;
  z-index: 3;
  background-color: #202020;
  transition: all .2s cubic-bezier(1, 0.08, 0.49, 0.86);
  &.active{
    bottom: 0;
  }
  .aplayer-bar{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0.053333rem;
    background-color: #3A3A3A;
    .aplayer-loaded{
      position: absolute;
      left: 0;
      top: 0;
      background: #aaa;
      height: 100%;
      transition: all .5s ease;
    }
    .aplayer-played{
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background-color: #FFFFFF;
    }
  }
  .random{
    position: absolute;
    left: 0.48rem;
    top: 0.4rem;
    width: 0.426666rem;
    height: 0.586666rem;
    background: url("../../static/img/random.png") no-repeat;
    background-size: cover;
  }
  .random.active{
    background: url("../../static/img/randoming.png") no-repeat;
    background-size: cover;
  }
  .palybtn{
    position: absolute;
    right: 0.48rem;
    top: 0.48rem;
    width: 0.4rem;
    height: 0.4rem;
    font-size: 0.4rem;
    color: #FFFFFF;
  }
  .aplayer-center{
    margin: 0 auto;
    width: 6.666666rem;
    height: 100%;
  }
  .aplayer-tatle{
    margin-top: 0.24rem;
    font-size: 0.32rem;
    line-height: 0.453333rem;
    font-weight:600;
    text-align: center;
    color: #FFFFFF;
  }
  .appname{
    font-size: 0.32rem;
    line-height: 0.453333rem;
    text-align: center;
    color: #858585;
    white-space:nowrap;
    overflow:hidden; 
    text-overflow:ellipsis; 
  }
}