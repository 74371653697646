@charset "utf-8";

/* 清除默认样式的文件 - 基础 */


body,html,#app{
  width: 100%;
  height: 100%;
}
body{
  background-color: #151515;
}
* {
  /*ios点击阴影问题*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  /*ios点击阴影问题*/
  padding: 0;
  margin: 0;
  outline: none;
  resize: none;
  font-family: 'PingFangSC-Semibold,PingFang SC;';
  word-wrap: break-word;
  word-break: break-all;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
}

a {
  color: #333;
  text-decoration: none;
}

ul,
ol {
  list-style: none;
}

li {
  list-style: none;
}

i,
em {
  font-style: normal;
}

b,
strong {
  font-weight: normal;
}

a img {
  border: none;
}

img {
  vertical-align: top;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.clearfix:after {
  display: block;
  content: '';
  clear: both;
}

.clearfix {
  zoom: 1;
}

th,
td {
  /*border: 1px solid #ccc;*/
  padding: 0;
}

table {
  border-collapse: collapse;
}

input {
  outline: none;
  border: none;
}

.fl {
  float: left;
}

.fr {
  float: right;
}
